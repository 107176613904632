<template>
  <div class="page home">
    <SearchBarVer3
      placeholder="搜索歌曲、歌星"
      :isShowBack="false"
      :isShowVipActEnter="false"
    ></SearchBarVer3>
    <div class="main-content">
      <div class="left">
        <!-- <MvMini v-if="isShowMini" />
        <MvPageComponent v-show="!isShowMini" ref="mvPageComponentRef" /> -->
        <!-- <MvCore /> -->
        <div class="left-bottom">
          <PromoBanner />
          <Swiper
            v-if="browserType === 'landscape'"
            class="left-bottom-swiper"
            :images="homeBannerImages"
            @slide-click="handleClickBanner"
            @change="handleBannerChange"
          />
        </div>
      </div>

      <div class="right">
        <div class="sheet-list" id="sheet-scroll">
          <div class="sheet-list-tab" id="refTab" ref="refTab">
            <div
              class="sheet-list-tab-item"
              v-for="item in rankList"
              :key="item.id"
              @click="handleChangeTab(item)"
              :data-name="item.name"
            >
              <div
                class="sheet-list-tab-item-txt"
                :class="{ 'sheet-list-tab-active': curTab.id == item.id }"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="sheet-list-content">
            <div class="sheet-list-content-cover vertical">
              <img :src="curTab.image" v-img-fallback="imgFallback" />
              <p>{{ curTab.name }}</p>
            </div>
            <LoadMore
              class="song-list"
              v-if="dataList.length"
              @load-more="fetchData"
              @watch-scroll="handleWatchScroll"
              safeAreaHeight="30vw"
            >
              <SongItem
                className="sheet-list-song-item"
                v-for="(songItem, index) in dataList"
                :key="index"
                :songItem="songItem"
                :index="index"
                :log-from="{
                  song_list_source: curTab.name === '新歌速递' ? 15 : 3,
                  song_list: curTab.name,
                  song_list_id: curTab.id,
                  fr1: curTab.id === 17075 ? 1892 : 1841,
                  fr2: curTab.id === 17075 ? '国庆K歌指南: 唱响中国梦' : '歌单-歌单名称',
                }"
                :show1080Icon="curTab.id === 20179 || curTab.id === 3211"
                :showNewSongIcon="curTab.name === '新歌速递'"
                @order-click="watchOrderClick"
              />
              <p
                class="bot-hint"
                v-if="isShowEmpty || curTab.name === '猜你会唱'"
              >
                到底啦～
              </p>
            </LoadMore>
            <div v-if="!isRequest && !dataList.length" class="empty">
              暂无歌曲
            </div>
          </div>
        </div>

        <!-- :firstSong="firstSong" -->
        <NavList @click-nav="handleClickNav" />
      </div>
    </div>

    <RecommendSong :isActive="isShowRecommend" @close="closeRecommend" />
  </div>
</template>

<script setup>
import { onMounted, ref, computed, onActivated, watch, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Toast from "@/utils/toast";
import eventBus from "@/utils/event-bus";
import { homeBannerImages } from "@/constants/index";
import PromoBanner from "@/components/nav-list/promo-banner.vue";
import Swiper from "@/components/swiper/index.vue";
import NavList from "@/components/nav-list/index.vue";
import SongItem from "@/components/song-item/home.vue";
// import MvMini from "@/components/mv/mini.vue";
// import MvPageComponent from "@/components/mv/index.vue";
import RecommendSong from "@/components/recommend-song/index.vue";
// import MvCore from "@/components/mv-core/index.vue";
import useLoginValidComposables from "@/composables/useLoginValid";
import useActivity from "@/composables/useActivity";
import { useShareBrowserSize } from "@/composables/sharedComposable";
import useVip from "@/composables/useVip";
// import { showActivityAiface } from "@/composables/useActivity";
import { getPlaylist } from "@/service/playlist";
import { getPlaylistDetail } from "@/service/playlist";
import { getHotSongList, getNewSongList } from "@/service/hotSongList";
import { sendLog } from "@/directives/v-log/log";
import {
  getRecommendSongTag,
  setRecommendSongTag,
  // setReduceRecommendModalTag,
  // getReduceRecommendModalTag,
  getReduceRecommendSetting,
} from "@/utils/historyCache";

const { browserType } = useShareBrowserSize();

const generateGuessSongList = (oftenSingList, top50List) => {
  let guessSongList = [];
  let oftenSingIndex = 0;
  let top50Index = 0;

  while (
    oftenSingIndex < oftenSingList.length &&
    top50Index < top50List.length
  ) {
    guessSongList.push(oftenSingList[oftenSingIndex]);
    oftenSingIndex++;

    if (oftenSingIndex < oftenSingList.length) {
      guessSongList.push(oftenSingList[oftenSingIndex]);
      oftenSingIndex++;
    }

    guessSongList.push(top50List[top50Index]);
    top50Index++;
  }

  guessSongList = guessSongList.concat(oftenSingList.slice(oftenSingIndex));
  guessSongList = guessSongList.concat(top50List.slice(top50Index));

  return guessSongList;
};

const router = useRouter();
const route = useRoute();
const store = useStore();
const { checkUserLoginStatus, showLoginQrcode } = useLoginValidComposables();
const { showActivityAiface } = useActivity();
// const { showActivityKSong } = useActivity();
const unionid = computed(() => store.state.userInfo.unionid);
// const firstSong = computed(() => {
//   if (store.state.oftenSing.oftenSingList.length) {
//     return store.state.oftenSing.oftenSingList[0];
//   } else {
//     return store.state.oftenSing.top50[0];
//   }
// });
const isLogin = computed(() => !!store.state.userInfo.unionid);
const oftenSingList = computed(() => store.state.oftenSing.oftenSingList);
const top50List = computed(() => store.state.oftenSing.top50);
const isGuessSongList = computed(
  () => isLogin.value && oftenSingList.value.length
);
const userType = computed(() => store.state.userInfo.userType);
const isVip = computed(() => !!store.state.vipInfo.end_time);
const freeSing = computed(() => store.state.climax.freeSing);
const mvIsHide = computed(() => store.state.mvIsHide);
// const orderedList = computed(() => store.state.orderedList);
// const videoPaused = computed(() => store.state.videoPaused);

const isShowEmpty = ref(false);
// const isShowMini = ref(true);

let curTab = ref({
  id: 0,
  name: "",
  img: "",
});
let p = 1;
let version = ref({
  current: "",
  latest: "",
});
let isRequest = ref(false);
let rankList = ref([]);
let dataList = ref([]);
const refTab = ref(null);
const imgFallback = {
  loading:
    "https://qncweb.ktvsky.com/20210926/vadd/8a6ac4b746c04f4701481e4da1e146b3.png",
  error:
    "https://qncweb.ktvsky.com/20211231/vadd/f2fe8a149cd948ff5ef096597f47f965.png",
};
// const mvPageComponentRef = ref(null);

let isShowRecommend = ref(false);
let lastScrollPos = 0;
// let timer = null;
// let isShowReduceRecommendModal = ref(false);
let reduceTimer = null;

const { showVipQrcode } = useVip();

const handleClickNav = (nav) => {
  if (nav.isCheckLogin && !checkUserLoginStatus()) {
    return;
  }
  if (!nav.isSupport) {
    Toast("功能尚未完成迁移");
    return;
  }
  if (nav.type === "page") {
    if (nav.pathName === "mine") {
      store.dispatch("getCarplayInfo");
    }
    if (nav.pathName === "climax") {
      sendLog({
        event_type: "10000~50000",
        event_name: 30236,
        event_data: {
          str1: "首页",
          str2: "快唱高潮",
          str3: "点击",
          str4: "click",
          str5: isLogin.value ? "已登录" : "未登录",
          str7: userType.value,
        },
      });
      if (!isLogin.value) {
        showLoginQrcode();
        return;
      }
      if (isLogin.value && !isVip.value) {
        if (freeSing.value) {
          showVipQrcode({
            log: `首页-底部运营位`,
            isLogin: isLogin.value,
            fr: 1759,
          });
          return;
        }
        eventBus.emit("free-singing-control-popup", nav.pathName);
        return;
      }
      sendLog({
        event_type: "10000~50000",
        event_name: 30144,
        event_data: {
          str1: "首页",
          str2: "快唱高潮",
          str3: "快唱高潮点击",
          str4: "click",
        },
      });
    }
    router.push({
      name: nav.pathName,
    });
    return;
  }
  if (nav.type === "emit") {
    eventBus.emit(nav.emit);
    return;
  }
};

const handleChangeTab = (tab) => {
  if (curTab.value.id == tab.id) return;

  const element = document.querySelector(`[data-name="${tab.name}"]`);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }
  isShowEmpty.value = false;

  sendLog({
    event_type: "10000~50000",
    event_name: 10065,
    event_data: {
      str1: "首页",
      str2: "歌单tab",
      str3: "切换歌单",
      str4: "click",
      str5: tab.name || "",
      str7: tab.id || "",
    },
  });
  curTab.value = tab;
  dataList.value = [];
  p = 1;
  version.value = {
    current: "",
    latest: "",
  };
  isRequest.value = false;
  fetchData();

  sendLog({
    event_type: "10000~50000",
    event_name: curTab.value.name === "猜你会唱" ? 30009 : 30011,
    event_data: {
      str1: "首页",
      str2: curTab.value.name === "猜你会唱" ? "猜你会唱" : "歌单",
      str3:
        curTab.value.name === "猜你会唱"
          ? "猜你会唱列表展现"
          : "任意歌单列表展现",
      str4: "show",
      str5: isLogin.value ? "已登录" : "未登录",
      str7: userType.value,
    },
  });
};

const initRankList = async () => {
  let res = await getPlaylist();
  if (res.length) {
    let filteredList = res.filter((v) => v.id !== 20179 || v.id !== 3211);
    const filter1080 = res.find(
      (item) => item.id === 20179 || item.id === 3211
    );

    filteredList = filteredList.filter((v) => v.name !== "新歌速递");
    const specialNameItem = res.find((item) => item.name === "新歌速递");
    const childrenNameItem = res.find((item) => item.name === "儿童专区");

    if (filter1080) {
      filter1080.icon =
        "https://qncweb.ktvsky.com/20240306/other/7ce38a00c035f2d83671132598ee848d.png";
      filteredList.unshift(filter1080);
    }
    if (childrenNameItem) {
      childrenNameItem.icon =
        "https://qncweb.ktvsky.com/20240530/vadd/3d9551df79e0a2ca7a558099db99fae9.png";
    }

    if (specialNameItem) {
      specialNameItem.icon =
        "https://qncweb.ktvsky.com/20240325/vadd/0dee287a5f90cef20dc21af5a7b31dbb.png";
      const index1080 = filteredList.findIndex((item) => item.id === 20179);
      if (index1080 !== -1) {
        filteredList.splice(index1080 + 1, 0, specialNameItem);
      } else {
        filteredList.unshift(specialNameItem);
      }
    }

    // 新增 新歌歌单，放在第四位
    // const newSongItem = {
    //   id: 10000,
    //   name: "中秋｜经典热歌",
    //   image:
    //     "https://qncweb.ktvsky.com/20240910/vadd/e9bf1ee2eccb241c0fc88b5f1b02c9f3.png",
    // };
    const newSongItem = {
      id: 17075,
      name: "国庆K歌指南: 唱响中国梦",
      image:
        "https://qncweb.ktvsky.com/20240926/vadd/ba65a34a1d47afec8049e0f4628915a3.png",
    };
    filteredList.splice(2, 0, newSongItem);

    filteredList = filteredList.slice(0, 10);

    rankList.value = [
      {
        name: "猜你会唱",
        image:
          "https://qncweb.ktvsky.com/20240521/other/a63dd979fba09ef898b4ecb1609e17d2.png",
      },
      ...filteredList,
    ];

    // console.log("rankList:", rankList.value);
    curTab.value = rankList.value[0];
    sendLog({
      event_type: "10000~50000",
      event_name: 30009,
      event_data: {
        str1: "首页",
        str2: "猜你会唱",
        str3: "猜你会唱列表展现",
        str4: "show",
        str5: isLogin.value ? "已登录" : "未登录",
        str7: userType.value,
      },
    });
    fetchData();
  }
};

const requestBussinessData = async () => {
  let responseData = await getPlaylistDetail({
    p,
    type: curTab.value.name,
    version: version.value.latest,
  });
  return responseData;
};

const requestHotSongData = async () => {
  const res = await getHotSongList({
    p,
    unionid: unionid.value,
    version: version.value.latest,
  });
  if (res.data.length) {
    if (p === 1 && res.version) {
      version.value = res.version;
    }
    dataList.value = dataList.value.concat(res.data).filter((v, i) => i < 100);
    p++;
  }
};

const requestNewSongList = async () => {
  const res = await getNewSongList({
    p,
    version: version.value.latest,
  });
  if (res.data.length) {
    if (p === 1 && res.version) {
      version.value = res.version;
    }
    dataList.value = dataList.value.concat(res.data);
    p++;
  }
};

const requestGuessSongList = () => {
  let guessSongList = isGuessSongList.value
    ? generateGuessSongList(oftenSingList.value, top50List.value)
    : top50List.value;
  dataList.value = guessSongList;
};

const fetchData = async () => {
  if (isRequest.value) {
    return;
  }
  isRequest.value = true;

  if (curTab.value.name === "热门歌曲，总有一首你会唱") {
    if (!dataList.value.length) await requestHotSongData();
    isRequest.value = false;
    return;
  }

  if (curTab.value.name === "猜你会唱") {
    if (!top50List.value.length) {
      await store.dispatch("oftenSing/initTop50List");
    }
    await requestGuessSongList();
    isRequest.value = false;
    return;
  }

  if (curTab.value.name === "新歌速递") {
    await requestNewSongList();
    isRequest.value = false;
    return;
  }

  // if (curTab.value.name === "中秋｜经典热歌") {
  //   dataList.value = top50List.value.filter(v => v.is_vip).map(t => ({
  //     ...t,
  //     sing_cnt: '',
  //   }));
  //   isRequest.value = false;
  //   return;
  // }

  const bussinessResponseData = await requestBussinessData();
  if (bussinessResponseData.data.length !== 0) {
    if (p === 1 && bussinessResponseData.version) {
      version.value = bussinessResponseData.version;
    }
    dataList.value = dataList.value.concat(bussinessResponseData.data);
    p++;
  }
  isRequest.value = false;
  if (bussinessResponseData.data.length < 10) {
    isShowEmpty.value = true;
  }
};

// const onCloseVipQrcode = () => {
//   isShowMini.value = true;
// };

const handleClickBanner = (index) => {
  console.log("banner:", index);
  if (index === 0) {
    sendLog({
      event_type: "10000~50000",
      event_name: 30235,
      event_data: {
        str1: "首页",
        str2: "“特别定制”歌单",
        str3: "点击",
        str4: "click",
        str5: isLogin.value ? "已登录" : "未登录",
        str7: userType.value,
      },
    });
    router.push({
      name: "songList",
      query: {
        name: "“特”别定制，会员专享歌单",
        image:
          "https://qncweb.ktvsky.com/common-web/operation_cms/1691133786343.png",
      },
    });
  } else if (index === 1) {
    console.log('aimv')
    sendLog({
      event_type: '10000~50000',
      event_name: 20290,
      event_data: {
        str1: '首页',
        str2: '换脸MV',
        str3: '点击',
        str4: 'click',
      }
    })
    showActivityAiface({})
  } else {
    console.log("no event need to do");
  }
};

const handleBannerChange = (e) => {
  // console.log('banner change:', e, route.name)
  if (e === 0 || route.name !== "home") return;

  if (!mvIsHide.value) return;

  sendLog({
    event_type: "10000~50000",
    event_name: 30257,
    event_data: {
      str1: "首页",
      str2: "轮播banner位-麦克风售卖",
      str3: "展示",
      str4: "show",
      // str5: isLogin.value ? '已登录' : '未登录',
      // str7: userType.value,
    },
  });
};

// const showReduceRecommendModal = () => {
//   if (isShowReduceRecommendModal.value) return;
//   if (getReduceRecommendModalTag()) return;
//   if (timer) clearTimeout(timer);
//   timer = setTimeout(() => {
//     // show reduce recommend modal
//     isShowReduceRecommendModal.value = true;
//     setReduceRecommendModalTag();
//   }, 30 * 1000);
// };

const showRecommend = () => {
  isShowRecommend.value = true;
  setRecommendSongTag();

  // showReduceRecommendModal();
};

const closeRecommend = () => {
  isShowRecommend.value = false;
};

// recommend active condition
const handleWatchScroll = (s) => {
  // console.log("handleWatchScroll", s);
  if (!getRecommendSongTag() && getReduceRecommendSetting() == 1) {
    if (Math.abs(lastScrollPos - s) > 150) {
      showRecommend();
    }
    lastScrollPos = s;
  }
};

// recommend active condition
const watchOrderClick = () => {
  if (!getRecommendSongTag() && getReduceRecommendSetting() == 1) {
    showRecommend();
  }
};

// reduce recommend modal active condition
// const watchRecommendClick = () => {
//   if (!getReduceRecommendModalTag()) {
//     showReduceRecommendModal();
//   }
// };

const handleDelayShowRecommend = () => {
  reduceTimer = setTimeout(() => {
    showRecommend();
  }, 60 * 1000);
};

onMounted(() => {
  initRankList();
  // eventBus.on("handle-close-vip-qrcode", onCloseVipQrcode);

  eventBus.on("handle-show-home-recommend", handleDelayShowRecommend);

  // 选择减少推荐频次，首页60s后自动弹
  if (getReduceRecommendSetting() == 2) {
    handleDelayShowRecommend();
  }
});

onUnmounted(() => {
  if (reduceTimer) clearTimeout(reduceTimer);
  // eventBus.off("handle-close-vip-qrcode", onCloseVipQrcode);

  eventBus.off("handle-show-home-recommend", handleDelayShowRecommend);
});

onActivated(() => {
  sendLog({
    event_type: "10000~50000",
    event_name: 10001,
    event_data: {
      str1: "首页",
      str2: "首页",
      str3: "进入首页",
      str4: "show",
    },
  });
});

// watch(
//   [mvIsHide, orderedList, isVip],
//   () => {
//     if (!videoPaused.value && orderedList.value.length) {
//       isShowMini.value = false;
//       return;
//     }

//     if (document.querySelector(".vip-modal-content")) {
//       return;
//     }

//     const shouldShowMini =
//       mvIsHide.value &&
//       (orderedList.value.length === 0 ||
//         (orderedList.value[0].is_vip && !isVip.value));

//     isShowMini.value = shouldShowMini;

//     if (isShowMini.value) {
//       mvPageComponentRef.value.handleVideoControlPause();
//     }
//   },
//   {
//     deep: true,
//   }
// );
</script>

<style lang="stylus" scoped>
.home
  display flex
  flex-direction column
  width 100vw
  height 100vh
  overflow hidden
  height 100vh
  padding 0px 40px 0px
  background: rgba(0, 0, 0, 0.7)
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding 24px 40px 130px

  .main-content
    overflow hidden
    display flex
    @media screen and (max-width 1200px) and (min-height 1200px)
      flex-direction column
      overflow visible

    .left
      &-bottom
        margin-top 584px
        display flex
        flex-direction row
        align-items center
        &-swiper
          width 484px
          height 334px
          border-radius 24px
          overflow hidden
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 1120px
        display flex
        justify-content right
        &-bottom
          margin-top 0
        &-bottom-swiper
          display none

    .right
      padding-left 32px
      @media screen and (max-width 1200px) and (min-height 1200px)
        display flex
        flex-direction column-reverse
        padding-left 0

    .sheet-list
      width 808px
      height 553px
      display flex
      flex-direction column
      position relative
      border-radius 24px
      background #FFFFFF1A
      margin-left 0px
      margin-bottom 32px
      overflow hidden
      border: 1px solid #FFFFFF26
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 100%
        height 1070px
        margin-bottom 38px
        margin-left 0px
      &-tab
        width 100%
        height 80px !important
        padding 0 18px
        display flex
        align-items flex-end
        overflow-x: scroll;
        overflow-y: visible
        margin-bottom 20px
        background #FFFFFF17
        &::-webkit-scrollbar
          display none
        &-item
          width auto
          height 80px
          line-height 80px
          display flex
          align-items center
          border-bottom none
          position relative
          padding 0 20px
          &-txt
            height 80px
            line-height 80px
            color #FFFFFFB2
            font-size 28px
            font-weight 400
            width auto
            white-space nowrap
            overflow hidden
            text-overflow ellipsis
            padding 0px !important
            @media screen and (max-width 1200px)
              font-size 26px
        &-active
          color #DBAE6A
          border-bottom 2px solid #DBAE6A
          font-weight 400
        @media screen and (max-width 1200px) and (min-height 1200px)
          height 72px
          margin-bottom 28px
          &-item
            height 72px
            &-txt
              height 72px
              line-height 66px
              font-size 26px
      &-content
        width 100%
        flex 1
        display flex
        justify-content space-between
        overflow hidden
        &-cover
          width 240px
          margin 20px 88px 0 48px
          img
            width 240px
            height 240px
            border-radius 24px
          p
            font-size 26px
            color #FFFFFFB2
            margin-top 20px
        .song-list
          width 100% !important
          height 100% !important
          padding-bottom 20px
          padding-right 0
          padding-left 30px
          &::-webkit-scrollbar
            display none
          @media screen and (max-width 1200px) and (min-height 1200px)
            flex 1
            padding-right 44px
        .loadmore
          padding-bottom 30px!important
        .empty
          flex 1
          margin-top 10vh
          font-size 28px
          color rgba(255, 255, 255, 0.5)
          text-align center
        @media screen and (max-width 1200px) and (min-height 1200px)
          margin-top 0
          flex 1
          flex-direction row
          &-left
            width 300px
            display flex
            flex-direction column
            align-items top
            padding-left 0
            img
              width 240px
              height 240px
              margin-top 30px
            p
              margin-left 0
              max-width 240px
              text-align left
              color rgba(255, 255, 255, 0.7)
          &-right
            width 100%
            padding-left 0
            margin-top 0
            .song-list
              height 73.6vh !important
              padding 0 20px
              padding-bottom 0 !important
  .bot-hint
    font-size 24px
    color #FFF
    opacity 0.5
    text-align center
    margin-top 80px
    width 100%
    text-align center
    @media screen and (max-width 1200px)
      margin-top 50px
</style>
